import { global } from "../constants";

export const data = {
  banner: {
    background: global.banner + "service1.png",
    title: "Our Services",
    crumb: "Our Services",
  },

  services: {
    title: "All services",
    subtitle: "Trusted For Our Services",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry the standard dummy text ever since the  when an printer took.",
    services: [
      {
        image: global.sgallery + "1.jpg",
        title: "Air Freight",
        serial: "01",
        description:
          "Our aim is to optimize and improve your supply chain so that we can give you the best service.",
      },
      {
        image: global.sgallery + "2.jpg",
        title: "Road Freight",
        serial: "02",
        description:
          "Our aim is to optimize and improve your supply chain so that we can give you the best service.",
      },
      {
        image: global.sgallery + "3.jpg",
        title: "Ocean Freight",
        serial: "03",
        description:
          "Our aim is to optimize and improve your supply chain so that we can give you the best service.",
      },
      {
        image: global.sgallery + "4.jpg",
        title: "Rail Freight",
        serial: "04",
        description:
          "Our aim is to optimize and improve your supply chain so that we can give you the best service.",
      },
      {
        image: global.sgallery + "5.jpg",
        title: "Warehousing",
        serial: "05",
        description:
          "Our aim is to optimize and improve your supply chain so that we can give you the best service.",
      },
      {
        image: global.sgallery + "6.jpg",
        title: "Project Cargo",
        serial: "06",
        description:
          "Our aim is to optimize and improve your supply chain so that we can give you the best service.",
      },
    ],
  },

  estimation: {
    background: global.back + "estimation.png",
    title: "Estimation",
    subtitle: "Has a wide range of solutions",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry the standard dummy text ever since the  when an printer took.",
    solutions: [
      {
        serial: "01",
        title: "Solutions and specialized expertise",
        subtitle:
          "Our aim is to optimize and improve your supply chain so that we can give you the best service",
      },
      {
        serial: "02",
        title: "Multiple warehouses",
        subtitle:
          "We provide multiple drop off and pickup locations so you don't have to worry. And you should not face any kind...",
      },
      {
        serial: "03",
        title: "Tracking made easy",
        subtitle:
          "A tracking number for the entire process. so that you can find the exact position. it will help you",
      },
    ],
  },

  booking: {
    background: global.booking + "bg-map.png",
    image: global.booking + "pic1.png",
    imageBack: "",
    title: "Current Operations",
    subtitle: "Booking For Product Transformation",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry the standard dummy text ever since the when an printer took.",
    form: {
      quote: global.booking + "icon1.png",
      track: global.booking + "icon2.png",
    },
  },

  clients: [
    global.logo + "w1.png",
    global.logo + "w2.png",
    global.logo + "w3.png",
    global.logo + "w4.png",
    global.logo + "w5.png",
    global.logo + "w1.png",
    global.logo + "w2.png",
    global.logo + "w3.png",
    global.logo + "w4.png",
    global.logo + "w5.png",
  ],
};
