import Banner from "../../sections/common/banner";
import SectionWhatWeDo1 from "../../sections/home/index/section-what-we-do1";
import SectionAchivements1 from "../../sections/home/index2/section-achivements1";
import SectionTestimonials1 from "../../sections/home/index/section-testimonials1";
import SectionBlogs1 from "../../sections/home/index/section-blogs1";
import SectionCompanyApproch1 from "../../sections/home/index/section-company-approch1";
import { data } from "../../../globals/data/terms-conditions";

export default function TCPage() {
  return (
    <>
      <Banner _data={data.banner} />
      <div className="privacy-policy-container">
        <h1>Terms & Conditions</h1>
        <p>
          Thank you for choosing the services of Pickkup. On entering in our
          mobile application or surfing the website, you hereby agree to abide
          by the below mentioned conditions altogether with our privacy policy
          as stipulated by Pickkup with regard to using the Pickkup app or web
          page and also with regard to the services as mentioned herein below.
          If there arises any contradiction of the conditions mentioned herein
          with the contentions of any agreement or document arrived at among the
          parties hereto, the conditions enumerated here would be relied upon.
        </p>
        <h2>"MEANINGS"</h2>
        <p>
          The below mentioned meanings shall be applicable to the conditions
          setforth that preside this agreement/contract of Carriage executed
          among you and us.
        </p>
        <ul>
          <li>
            Pickkup will supervise its employees, authorized agents and
            individual contractors that do or undertake to do or carry the
            consignment or any other services hereunder or incidental thereto on
            its behalf.
          </li>
          <li>
            The words 'We', 'us', 'our', 'transporter', 'Pickkup' shall refer to
            ___________________ Private Limited, which owns Pickkup
          </li>
          <li>
            The words 'You', 'your', 'consignor' denote to the consignor, sender
            or holder of this Consignment Note, consignee of the Consignment,
            receiver and owner of the articles of the Consignment or any other
            party having a lawful interest in those articles, as the case may
            be.
          </li>
          <li>
            The words 'Receiver' or 'Consignee' relate to the addressee or
            recipient or the consignee of the load.
          </li>
          <li>
            The word 'Consignment' says any parcel, satchel, package or freight
            which is or are given to and accepted by Pickkup for carriage under
            our Consignment Note.
          </li>
          <li>
            The word 'Carriage' refers and consists the whole of the operations
            and services undertaken by Pickkup relating to Consignment.
          </li>
          <li>
            The word 'Delivery' refers to the tender of the consignment to the
            receiver or intimation about the arrival of the consignment.
          </li>
          <li>
            The words 'Hazardous Goods' denote goods bifurcated as hazardous as
            per ICAO T.I., IATA DGR, IMDG-Code, ADR or other national
            regulations for transport.
          </li>
          <li>
            The words 'Forbidden Items' depict any materials, the Carriage of
            which is forbidden by Law Applicable within the territory.
          </li>
          <li>
            The words 'Law Applicable within the territory' refer to all laws,
            ordinance, statutes, guidelines, regulations, policies, rules,
            bye-laws, notifications, directions, directives and orders or other
            governmental restrictions or any similar form of decision of, or
            determination by, or any interpretation, administration and other
            pronouncements having the effect of law of the Republic of India or
            any other applicable jurisdiction by state, court, tribunal,
            government, ministry, department, municipality, commission,
            arbitrator or board or such other entity which has the force of law
            in India.
          </li>
        </ul>

        <h2>HOW USERS ARE ELIGIBLE?</h2>
        <p>
          First of all, the definition of word 'User/s' includes any individual
          or business group/entity/organization that lawfully carryout, uses or
          has the right to use the services provided by PICKKUP in India or in
          other countries. The Services given by PICKKUP is a technical service
          through the mode of internet, which enables the hiring of
          transportation vehicles i.e. mini-trucks/trucks/goods carrier or any
          other kind of commercial vehicle by customers for a point to point
          service, or for time and usage based service within or outside the
          limits of city, consisting of inter-city throughout India, via
          internet and / or mobile telecommunications devices. The Services are
          available only to those individuals or companies who can enter into
          contracts legally binding under the Law Applicable within the
          territory. Thus, user(s) must be major as per Law; viz. user(s) must
          have attained 18 years of age to become competent to avail our
          Services.
        </p>
        <p>
          PICKKUP advises its users/customers that while having access to the
          web page, the related laws must be abided by. PICKKUP in any manner
          will not be responsible for the feasible repercussions arisen by your
          conduct during use of web page. PICKKUP may refuse the service to
          anyone at any point of time, in its sole discretion.
        </p>

        <h2>AGREEMENT APPLICABLE TO THE USER/S</h2>
        <p>
          The present agreement shall apply to all PICKKUP Services
          available/offered on the website, altogether with any additional
          condition that may be applicable to any specific service availed by
          user/s. In the event of any inconsistency or contradiction among any
          provision of the terms and conditions contained herein with those of
          the particular service, the provisions of the terms and conditions
          applicable to such specific Services shall be effective.
        </p>
        <p>
          This agreement shall apply to user/s being visitors, registered - free
          or paid user(s), who will access the web page for any kind of purpose.
          It shall also apply to any legal body, which you represent, under
          actual or clear authority. User/s may use this site only for their
          personal or internal requirements.
        </p>

        <h2>CHANGES IN USER/S AGREEMENT</h2>
        <p>
          PICKKUP has right, title and authority to amend, alter, correct,
          rectify, modify, or update the agreement at any time the need arises,
          without any prior notification to user/s and the modified terms and
          conditions of use shall prevail and come into effect immediately from
          its amendment. If you do not accede to the amendments, you must
          refrain yourself from using the service. If you continue to use the
          services, it will show your acceptance of the amended terms. It shall
          be binding upon user/s to abide by any amendment made in any policy or
          agreement from time to time, referred to in the present conditions
          applicable for Service.
        </p>

        <h2>INTELLECTUAL PROPERTY RIGHTS</h2>
        <p>
          PICKKUP is the sole owner or lawful licensee of all the rights to the
          webpage, mobile application and its contents. The content of
          webpage/website means its layout, texture, design, images, text,
          graphics, sound, video etc. The web site content embodies secrets of
          trade and rights of intellectual property protected under worldwide
          copyright and other laws. All title, ownership and intellectual
          property rights in the website and its contents shall remain with
          PICKKUP, its affiliates or licensor's of PICKKUP content, as the case
          may be.
        </p>
        <p>
          We do not accept any responsibility for any mistakes or omissions, or
          for the results achieved out of this information. The entire
          information provided here is 'AS IS' with no guarantee of totality,
          correctness, timeliness or of the outcome obtained from its use, and
          without warranty of any kind, implied or express, including, which is
          not limited to any kind of performance's guarantee or warranty,
          merchantability and fitness for a specific purpose. PICKKUP shall not
          be liable for any direct or indirect, incidental or consequential
          damages of any kind whatsoever with respect to the Service. You being
          user/reader hereby acknowledge that the risk of any kind shall be your
          responsibility on account for your dependency upon any content.
        </p>
        <p>
          We reserve all the rights hereby, which not otherwise claimed through
          this agreement or by PICKKUP. We intend to contain the information in
          the mobile application and website, only to provide general
          information for the personal use of the user/reader, who accepts full
          responsibility for its use. PICKKUP does not represent or endorse the
          accuracy or reliability of any information, or advertisements
          (collectively, the "content") included, distributed via, or concerned,
          downloaded or accessed from any of the Services which is part of this
          website, or the quality of any products, information or other
          materials show-casted, or obtained by customer as an outcome of an
          advertisement or any other information or offer in or in connection
          with the Service.
        </p>
        <p>
          We on behalf of PICKKUP also reserve the right, in its sole discretion
          and without any obligation, to improve, or rectify any error or
          mistake in any portion of the Service or the mobile application or
          website.
        </p>

        <h2>THIRD PARTY'S SITES</h2>
        <p>
          PICKKUP does not have any control over sites of third party regarding
          which links are provided by mobile app or website as a convenience to
          users.
        </p>
        <p>
          It is our belief that user/s acknowledge that PICKKUP has no control
          over such third party's site, does not monitor such sites, and PICKKUP
          shall not be responsible or liable to anyone for such third party
          site, or any content, products or Services made available on such a
          site.
        </p>

        <h2>TRADEMARK OF PICKKUP</h2>
        <p>
          It is clarified here every concerned icon and logos are trademarks
          duly registered or which are logo identification mark of PICKKUP in
          different jurisdictions. The same are safeguarded under applicable
          trademark, copyright or other proprietary rights laws. The imitating,
          copying, modification, use or publication of trademarks/logo of
          PICKKUP is deemed to be illegal and it can invite legal action for the
          person committing this act.
        </p>

        <h2>COPYRIGHT</h2>
        <p>
          The contents of PICKKUP if copied in a organized manner to gather or
          develop or create, straightway or in some way, a compilation,
          collection, database or directory (whether through manual or automatic
          mode such as spiders, robots) without prior consent or permission in
          writing from PICKKUP is forbidden and shall be a crime under law.
        </p>
        <p>
          Conditionally, through access by customer or user and use of PICKKUP's
          Services, you agree that you will not use the website's service to
          infringe the intellectual property rights of others in any way.
          Additionally, use of the content for any purpose not expressly
          permitted in this Agreement is prohibited and may invite legal action.
        </p>
        <p>
          We for PICKKUP also reserve the right to cancel the account of a
          user/s upon any breach of the rights of others in collaboration with
          use of the PICKKUP service, or if PICKKUP finds that conduct of user/s
          is harmful to the interests of PICKKUP, its affiliates, or other
          users, or for any other reason in PICKKUP's sole discretion, with or
          without cause. For any losses or expenses suffered or borne by PICKKUP
          due to your any infringement of intellectual property of PICKKUP, you
          shall be responsible to indemnify PICKKUP for same, without
          prejudicing PICKKUP’s right to bring any legal action against you.
        </p>
        <h2>CANCELLATION</h2>
        <p>
          There is no charge for the content and features provided on the mobile
          application or website. However, PICKKUP reserves its right to
          disallow access to certain areas or certain features of the mobile
          application or website at any time without assigning any cause, with
          or without notice. Further, PICKKUP also reserves the ultimate right
          to refuse access to any user or group of users of its Services without
          any prior notice or justification in order to safeguard the legal and
          vested interests of PICKKUP and/or other visitors to the website or
          mobile app.
        </p>
        <p>
          Additionally, PICKKUP reserves its right to limit, refute or create
          separate access to the website or mobile app and its features with
          respect to different user/s, or to change any of the features or
          introduce new features without prior intimation or notice.
        </p>

        <h2>TERMS AND CONDITIONS RELATING TO USE OF SERVICE</h2>
        <p>
          The customers who will avail the Services offered by PICKKUP for the
          hiring of transportation vehicles, they shall abide by following Terms
          & Conditions:
        </p>
        <p>
          The customer/s agrees and accepts that it will be their risk when they
          use of the Services provided by the Company, and further affirms that
          the Company disclaims all representations and warranties of any kind,
          whether express or implied.
        </p>
        <p>
          The customer shall pay the charges of fare (as agreed), parking,
          additional night surcharge (wherever applicable) and any fee or levy
          currently payable or hereinafter imposed by the Law applicable or
          required to be paid for availing of the transportation vehicle such as
          mini-truck Services.
        </p>
        <p>
          It shall be ensured by the customer/user that he/she will not indulge
          in any of the following activities while availing the service from
          PICKKUP:
        </p>
        <ul>
          <li>
            Insisting driver to breach any rules formulated by RTO/Traffic or
            City Police and/or by government for any purpose. The driver has the
            right to deny the same or any other request by customer if deemed it
            right to driver.
          </li>
          <li>
            Causing any kind of damage to the body or any other internal parts
            including any mechanical or non mechanical device of transportation
            vehicle.
          </li>
          <li>
            Not to compel the driver to put excess weight of consignment in the
            truck than permitted.
          </li>
        </ul>
        <p>
          The Company is fully competent and authorized to use the location
          based information provided by any of the telecommunication companies
          when the Customer uses the mobile phone to make booking of a
          transportation vehicle such as mini-truck. The location based
          information will be used only to facilitate and improve the
          probability of locating a transportation vehicle for the Customer.
        </p>
        <p>
          The Customer shall indemnify Company from and against and in respect
          of any or all liabilities, losses, charges and expenses (including
          legal fees and costs on a full indemnity basis) claims, demands,
          actions and proceedings which Company may incur or sustain directly or
          indirectly from or by any reason of or in relation to the use or
          proposed use of the Services by the Customer and shall pay such sums
          on demand on the Company.
        </p>
        <p>
          The Company shall be entitled to cancel the booking of the truck/
          transportation vehicle at any time without any reason or explanation,
          so done by the Customer. The user/s shall indemnify PICKKUP with
          respect to any expenses borne with respect to such booking.
        </p>
        <p>
          The Company shall be entitled to disclose to all entities/companies
          within its group, or any government body as so required by the Law
          applicable or on request from any government body, the particulars of
          the Customer in the possession of Company in any way as Company, in
          its absolute discretion, deems appropriate or if it considers it in
          interests of PICKKUP to do so.
        </p>
        <p>
          If there is any complaint relating to the Services or the use of the
          transportation vehicle, the Customer shall be bound to give intimation
          of the same to the Company in writing within 24 hours of using the
          transportation vehicle or the Services of Company.
        </p>
        <p>
          If any item is misplaced within the premises of Company during the
          journey, Company will make best efforts to trace such items but there
          shall be no responsibility of the company for the same if there occur
          any loss or damage to the item. There shall be no right available to
          the user/s to retain or withhold the payment payable to PICKKUP if any
          item is lost. Further, in case of pendency of any payments from the
          User/s towards PICKKUP for the time span prescribed by PICKKUP in its
          respective invoice/bill, PICKKUP reserves the right to levy specific
          lien over the consignment till full payment is made to PICKKUP for its
          services. Also, User/s will be liable to compensate PICKKUP against
          any loss, damage or expenses borne by the company due to the custody
          of the consignment during this period.
        </p>
        <p>
          There shall be no responsibility of the company relating to behaviour
          of the driver/s of the transportation vehicle/s. However, the Company
          motivates the customer to notify about any complaints to company that
          he or she might have against the driver hired for using the Company's
          Services.
        </p>
        <p>
          Company can record all the calls made to call centre for quality and
          training purposes.
        </p>
        <p>
          In case, customer places a query on mobile app or website of company
          with respect to our Services, applicable fees or terms of Service,
          customer hereby expressly agrees to consent to receive our responses,
          either through telephonic calls or email, to such query and all
          related information with respect to our Services.
        </p>
        <p>
          To clear any doubts, related information shall include without
          limitation any marketing and/or commercial information. Customer
          understands and agrees that such information shall not in any manner
          qualify as unsolicited commercial communication under the Telecom
          Unsolicited Commercial Communications Regulations, 2007 and/or due to
          disclosure of such information, our telephone numbers shall not
          qualify to be registered under the 'National Do Not Call Register' or
          the 'Private Do Not Call Register' in accordance with the Telecom
          Unsolicited Commercial Communications Regulations, 2007 or any other
          Applicable Law.
        </p>
        <p>
          The Company shall be entitled to add to, vary or alter any or all
          these terms and conditions at any time and the Customer shall be bound
          to abide by such addition, variation or amendment once such addition,
          variation or amendment are incorporated into these terms and
          conditions at Company's website at www…………………. on the date that
          Company may indicate that such addition, variation or amendment is to
          come into effect.
        </p>
        <p>
          Cancellation rules: All cancellations made 5 minutes after driver
          allocation will incur a cancellation fee of Rs.50. Cancelling four
          bookings in a day after driver allocation will temporarily suspend
          your account for 24 hours. Figures are subject to change.
        </p>
        <p>
          Toll Charges: In case of a toll on your trip, return toll fare will be
          charged.
        </p>
        <p>
          HIPAA is a set of laws that applies to healthcare information. We are
          not a healthcare provider so those laws don’t apply to us
        </p>
        <p>The purposes of the collection are as follows:</p>
        <p>
          Pickkup is not a healthcare provider and does not provide medical
          care, advice, diagnosis, treatment or services and your use of our
          Services does not create a patient/physician relationship. As such, we
          specifically disclaim any representation or warranty that Pickkup
          Services, as offered, comply with the federal Health Insurance
          Portability and Accountability Act (“HIPAA”). Pickkup only maintains
          the minimum data necessary to provide our services, does not store or
          maintain your protected health information (PHI) and is not compliant.
        </p>

        <h2>PRIVACY</h2>
        <p>
          The information received by the customer under the contract of service
          with PICKKUP shall not be disclosed to any third party under any
          situation. Any information which pertains and give access to business
          of PICKKUP shall be kept reserve/confidential to the extent it might
          adversely effect PICKKUP’s business.
        </p>
        <h2>DISCLAIMER</h2>
        <p>
          It is clarified that PICKKUP reserves its right to use the customer
          contact information for its own marketing purposes. The Company may
          send regular SMS updates to the mobile numbers registered with it.
        </p>
        <p>
          It is expressly made clear that all vehicles registered with the
          Company will be continuously tracked using mobile technology for
          security reasons that the Company does not own any transportation
          vehicle such as mini-truck nor does it directly or indirectly employ
          any drivers for the vehicle. The Mini-trucks and drivers are all
          supplied by third parties and the Company disclaims any and all
          liability in respect of the drivers and the mini-trucks alike.
        </p>

        <h2>STIPULATIONS RELATING TO ACE PLUS</h2>
        <p>
          The customers who will avail services for hiring Ace Plus shall be
          bound to follow the below-mentioned stipulations:
        </p>
        <ul>
          <li>Labour charge will be automatically made part of the invoice.</li>
          <li>
            Loading/unloading of ground floor to ground floor is only permitted.
          </li>
          <li>
            Maximum load per box allowed is 25 kg, to the extent of only upto 30
            such boxes.
          </li>
          <li>
            Distance between Pickkup truck and loading/unloading point should
            not be more than 25 feet (8 metres).
          </li>
        </ul>

        <h2>INFORMATION RELATING TO INSURANCE</h2>
        <p>
          The terms and conditions relating to Insurance should be referred to.
        </p>
        <h2>INFORMATION RELATING TO PICKKUP WALLET</h2>
        <p>
          Please find Pickkup wallet related FAQs and terms & conditions here.
        </p>

        <h2>ACCEPTANCE OF CUSTOMER/S TO TERMS AND CONDITIONS OF PICKKUP</h2>
        <p>
          Once the consignment is assigned by you/customer to the company, you
          accept our terms and conditions set forth in the consignment note
          and/or the contract of carriage and/or the contract for the
          performance of other services on behalf of yourself and/or anyone else
          who has an interest in the consignment or the performance of other
          services irrespective of whether you have signed the front of our
          consignment note or not.
        </p>
        <p>
          Our terms and conditions also cover and can be invoked by anyone we
          use or sub-contract to collect, transport, deliver your consignment or
          perform other services as well as our employees, directors and agents.
        </p>
        <p>
          Only one of our authorised officers may agree to a variation of these
          terms and conditions in writing. When you give us the consignment with
          oral or written instructions that conflict with our terms and
          conditions we shall not be bound by such instructions.
        </p>
        <h2>OBLIGATIONS OF CUSTOMER/S</h2>
        <ul>
          <li>
            By agreeing to the terms and conditions, you hereby affirm, abide
            and undertake to us:
          </li>
          <ul>
            <li>
              That the contents of the Consignment are not
              Prohibited/objectionable items and/or are not restricted by the
              applicable regulations and that you will supply to us any
              Dangerous Goods declaration that is needed, properly and
              accurately in accordance with Law applicable and neither you nor
              the consignee is a person or organisation with whom we or you may
              not legally trade under Law applicable.
            </li>
            <li>
              Further, the items of the consignment (including but not limited
              to weight and number of items) have been properly mentioned on
              consignment note and that the Consignment Note contains complete
              particulars in all respects and the documents as required for the
              Tran consignment including invoice, permits are enclosed with the
              Consignment Note.
            </li>
            <li>
              We are empowered to make delivery of goods at the address given on
              the Consignment Note and without prejudice, it is expressly agreed
              that we shall be conclusively presumed to have delivered the goods
              in accordance with this contract.
            </li>
            <li>
              The delivery confirmation will be sent by us via SMS/emails,
              no-response within 24 hours would be considered as an acceptance
              to the delivery.
            </li>
            <li>
              That all documents, statements as well as information provided by
              the customer relating to the Consignment will be true and correct,
              duly acknowledged by the customer that in the event that he or she
              makes untrue or fraudulent statement about the Consignment or any
              of its contents, the customer would risk a civil claim and/or
              criminal prosecution the penalties for which may include
              forfeiture and sale. The customer also agrees to indemnify the
              company and hold it harmless from any claims that may be brought
              against the company or its agents arising from the information
              provided by the customer.
            </li>
            <li>
              Further, that the customer has declared the accurate weight of the
              consignment and also will provide any special equipment the
              company may need to load or unload the consignment on or off its
              vehicles.
            </li>
            <li>
              When the customer has asked the company to charge the receiver or
              a third party and the receiver or third party does not pay the
              company, the customer will promptly settle our invoice together
              with an administration fee in full within 7 days of the company
              sending invoice to the customer.
            </li>
          </ul>
          <li>
            The customer certifies that all statements and information provided
            relating to the transportation of the consignment are true and
            correct.
          </li>
          <li>
            To the extent that the company may voluntarily assist the customer
            in completing the required formalities/customs and such assistance
            will be rendered at the customer's sole risk.
          </li>
          <li>
            The customer agrees to indemnify the company and hold it harmless
            from any liabilities the company may suffer or any costs, damages or
            expenses, including legal costs, it incurs either to the customer or
            to anyone else arising out of the customer being in breach of any of
            these warranties, representations and guarantees, even if the
            company inadvertently accepts a consignment that contravenes any of
            obligations of the customer.
          </li>
          <li>
            The customer agrees and acknowledges that the use of the Services
            offered by Company is at the sole risk of the customer and that
            Company disclaims all representations and warranties of any kind,
            whether express or implied as to condition, suitability, quality,
            merchantability and fitness for any purposes are excluded to the
            fullest extent permitted by Law applicable. Without prejudice to the
            above, the Company makes no representation or warranties with
            respect to:
          </li>
          <ul>
            <li>
              Any responsibility or liability for any loss or damage, howsoever
              caused or suffered by the Customer arising out of the use of truck
              service offered by Company or due to the failure of Company to
              provide Services to the Customer for any reason whatsoever
              including but not limited to the Customer's non-compliance with
              the Services' recorded voice instructions, malfunction, partial or
              total failure of any network terminal, data processing system,
              computer tele-transmission or telecommunications system or other
              circumstances whether or not beyond the control of Company or any
              person or any organization involved in the above mentioned
              systems.
            </li>
            <li>
              Any liability for any damages of any kind arising from the use of
              the Service offered by the Company, including, but not limited to
              direct, indirect, incidental, punitive, and consequential damages.
            </li>
            <li>
              Any additional or extra charges for far off locations & toll
              charges as well.
            </li>
            <li>The Services meeting the customer's requirements.</li>
            <li>
              The Services will be uninterrupted, timely, secure, or errorfree.
            </li>
            <li>
              Any alternate arrangement/s if the mini-truck has not reached due
              to any reason.
            </li>
          </ul>
          <li>
            The company will not be liable if it does not fulfill any
            obligations towards the customer at all as a result of:
          </li>
          <ul>
            <li>
              Circumstances beyond our control such as (but not limited to)
            </li>
            <ul>
              <li>
                National or local disturbance in air or ground transportation
                networks and mechanical problems to modes of transport or
                machinery.
              </li>
              <li>
                Force majeure including (but not limited to) war, accidents,
                acts of public enemies, strikes, embargoes, perils of the air,
                local disputes or civil commotions.
              </li>
              <li>
                Acts of god including earthquakes, cyclones, storms, flooding,
                fire, disease, fog, snow or frost or other natural calamities or
                disasters
              </li>

              <li>Criminal acts of third parties such as theft and arson.</li>
              <li>
                Latent defects or inherent vice in the contents of the
                consignment.
              </li>
            </ul>
            <li>
              The acts or omissions of customer or those of third parties such
              as
            </li>
            <ul>
              <li>
                The customer being in breach of (or any other party claiming an
                interest in the consignment causing the customer to breach) his
                or her obligations under these terms and conditions.
              </li>
            </ul>
            <li>
              The contents of the consignment consisting of any article that is
              a forbidden item even though the company might may have accepted
              the consignment by mistake.
            </li>
          </ul>
        </ul>
        <h2>GOODS UNDER THE AMBIT OF DANGEROUS / SECURITY</h2>
        <ul>
          <li>Dangerous Goods</li>
          <ul>
            <li>
              As per our sole discretion, we may accept some Dangerous Goods for
              carriage, or for the performance of other services, in some
              locations if you have been accorded the status of an approved
              customer and this must be given by us in writing before your
              consignment can be accepted.
            </li>
            <li>
              Your Dangerous Goods will only be accepted if they comply with the
              applicable regulations and our requirements. Details of our
              requirements together with the procedure for applying for approved
              customer status are available from our nearest office and a
              dangerous goods surcharge will be invoiced to you upon acceptance
              of your consignment.
            </li>
            <li>
              We do not transport, nor perform any other services relating to
              goods which in sole opinion are Dangerous Goods including, but not
              limited to, those specified in the guidelines, instructions,
              regulations, guidelines, codes applicable to us and our business
              or to the transport of, or the performance of other services with
              regard to Dangerous Goods.
            </li>
          </ul>
          <li>
            Forbidden Items: We do not accept consignments that contain
            forbidden items.
          </li>
          <li>
            There will be no responsibility of the company whatsoever as to the
            correctness of description, type or value thereof and you agree to
            indemnify us and hold us harmless from any claims that may be
            brought against us arising out of or relating to such declaration
            provided by you and any costs we will incur relating thereto. We
            accept consignments only upon your declaration of the type and value
            of the consignment (“said to contain” basis).
          </li>
        </ul>

        <h2>REFUSAL OF CONSIGNMENTS</h2>
        <p>
          Despite of refusal of customer/receiver to accept delivery, the
          company will try to contact the customer and resort to possible next
          action. The customer agrees to pay the company any costs the company
          bears in forwarding, disposing of or returning the consignment and its
          charges, if any, for the agreed appropriate next action.
        </p>
        <p>
          If user/s terminates the agreement with PICKKUP with respect to any
          consignment, user/s shall be liable to pay PICKKUP the entire fees and
          other expenses so incurred with respect to such consignm
        </p>
        <h2>IF CLAIM/S BRINGFORTH BY THIRD PARTIES</h2>
        <p>
          The customer agrees that he/she shall not allow any other person who
          has an interest in the consignment to bring a claim or action against
          the company arising out of Carriage even though the company may have
          been negligent or in default and if a claim or action is made the
          customer will indemnify the company against the consequences of the
          claim or action and the costs and expenses the company incurs in
          defending it.
        </p>
        <p>
          The above mentioned terms and conditions of use and/or Agreement and
          the Privacy Policy constitute the entire agreement between the User/s
          and PICKKUP with respect to access to and use of the web site and the
          Services offered by PICKKUP, superseding any prior written or oral
          agreements in relation to the same subject matter herein.
        </p>
        <h2>SCHEMES RELATING TO CASHBACKS</h2>
        <p>Terms and conditions relating to cashback can be referred to.</p>
        <h2>PICKKUP REFERRAL PROGRAM</h2>
        <p>Please find all referral related terms and conditions here.</p>
        <h2>ASSURED BOOKING TERMS</h2>
        <ul>
          <li>
            This scheme is eligible for __________ open category bookings
            between _____-_______ Hours only.
          </li>
          <li>
            This scheme is eligible when the pickup location is from _________
            clusters in Punjab only.
          </li>
          <li>
            Only the users who have received the communication are eligible
            under this scheme.
          </li>
          <li>
            Each user will only be eligible once a day for this scheme i.e. a
            user will be eligible for a maximum of Rs 50 cashback in a day in
            case of unfulfilled booking.
          </li>
          <li>
            The cashback will be auto credited in user's account within 24 hours
            of an unfulfilled booking.
          </li>
          <li>
            Management decision will be considered final with regard to all
            aspects of the scheme.
          </li>
        </ul>
        <h2>GST</h2>
        <ul>
          <li>GST, as applicable, will be levied on the Invoice.</li>
          <li>
            Taxes on reverse charge mechanism, wherever applicable, shall have
            to be paid by the recipient of services.
          </li>
          <li>
            Any information shall be considered only prospectively. Under no
            circumstances, will invoices be revised retrospectively for delay in
            providing GST registration information.
          </li>
        </ul>
        <h2>GOVERNING LAW AND JURISDICTION</h2>
        <p>
          These Terms of Service shall be governed by and construed in
          accordance with the laws of the India, without regard to the
          principles of conflict of laws. The courts of [Punjab] shall have
          exclusive jurisdiction over any disputes, differences or claims
          arising out of or in connection with these Terms of Service or any
          Services provided by us pursuant to these Terms of Service.
        </p>
        <h2>DISCLAIMER</h2>
        <p>
          The information contained in the mobile app or website of Pickkup is
          for general information purposes only. The information is provided by
          Pickkup and while we endeavor to keep the information up to date and
          correct, we make no assurances or representations or warranties of any
          kind, express or implied, about the completeness, accuracy,
          reliability, suitability or availability with respect to the website
          or the information, products, services, or related graphics contained
          on the website for any purpose. Any reliance you place on such
          information is therefore strictly at your own risk.
        </p>
        <p>
          In no event will we be liable for any loss or damage including without
          limitation, indirect or consequential loss or damage, or any loss or
          damage whatsoever arising from loss of data or profits arising out of,
          or in connection with, the use of this website.
        </p>
        <p>
          We assure that we will make every possible and best effort to keep our
          website and mobile app upto date, so that it could run smoothly,
          Pickkup takes no responsibility for, and will not be liable for, the
          website or mobile application being temporarily not working or
          unavailable due to any technical issues, whih might be beyond our
          control.
        </p>
      </div>
    </>
  );
}
