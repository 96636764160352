import { NavLink } from "react-router-dom";
import { publicUrlFor, loadScript } from "../../../../globals/constants";
import { useEffect } from "react";
import SectionCompanyApproch1 from "../index/section-company-approch1";
import { data } from "../../../../globals/data/about-us";
export default function SectionSlider2() {
  useEffect(() => {
    loadScript("/assets/js/slider-home2.js", true);
    loadScript("/assets/js/bg-image-move.js", true);
  });

  return (
    <>
      <div className="twm-slider2-wrap">
        {/* Swiper */}
        <div className="swiper twm-slider2">
          <div className="swiper-wrapper">
            {/* Slide One */}
            <div className="swiper-slide video-slide">
              <video autoPlay loop muted className="bg-video">
                <source
                  src={publicUrlFor(
                    "/assets/images/main-slider/slider3/home.mp4"
                  )}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
              <div className="content-overlay">
                <div className="h-banner-wrap">
                  <div className="h-banner-left">
                    <div className="h-bnr-top">
                      <h2>
                        <span className="title-outline">
                         End-to-End Logistics Solutions
                        </span>
                      </h2>
                      <p>
                        <span className="title-outline">
                          Building a sustainable business by saving carbon
                          emissions by utilising electric vehicles
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="circle-left-top zoon-in-out" />

                  {/* Three Boxes in Bottom-Left */}
                  <div className="bottom-left-boxes">
                    <div className="box">50K+ Deliveries</div>
                    <div className="box">1 Lakh+ Green KMs</div>
                    <div className="box">600+ Individual Clients</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* navigation Numbers */}
          <div className="swiper-pagination" />
          {/* navigation Arrow */}
          <div className="swiper-button-prev" />
          <div className="swiper-button-next" />
        </div>
      </div>
    </>
  );
}
