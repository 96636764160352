export default function SectionBooking1({ _data }) {
  let freight = ["Freight Type", "..."];
  let incoterms = ["Incoterms", "..."];
  let shipments = ["Shipment Type", "Road", "Train", "Air", "Sea"];

  return (
    <div
      className="section-full p-t120 p-b90 site-bg-gray tw-booking-area tw-booking-area-bg-wrap"
      // style={{ backgroundImage: `url(${_data.background})` }}
    >
      <div
        className="tw-booking-area-bg"
        style={{ backgroundImage: `url(${_data.imageBack})` }}
      ></div>

      <div className="container">
        {/* <!-- TITLE START--> */}
        <div className="section-head center wt-small-separator-outer">
          <div className="wt-small-separator site-text-primary">
            <div>{_data.title}</div>
          </div>
          <h2 className="wt-title">{_data.subtitle}</h2>
          {/* <p className="section-head-text">{_data.description}</p> */}
        </div>
        {/* <!-- TITLE END--> */}
      </div>
      <div className="container">
        <div className="tw-booking-section">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-12">
              <div className="tw-booking-media">
                <div className="media"></div>
              </div>
            </div>

            <div className="col-xl-9 col-lg-9 col-md-12">
              {/* <div className="tw-booking-form">
                <div className="row booking-tab-container">
                  <div className="col-lg-2 col-md-2 booking-tab-menu">
                    <div className="list-group">
                      <button className="list-group-item active text-center">
                        <div className="media">
                          <img src={_data.form.quote} alt="#" />
                        </div>
                        <span>Current Operations</span>
                      </button>
                      <button className="list-group-item text-center">
                        <div className="media">
                          <img src={_data.form.track} alt="#" />
                        </div>
                        <span>Track & Trace</span>
                      </button>
                    </div>
                  </div>
                 
                </div>
              </div> */}
              <img className="map-view" src="/assets/images/map2.png" alt="" width="100%" />
            </div>
          </div>
        </div>
      </div>

      
    </div>
  );
}



// export default function SectionBooking1({ _data }) {
//   let freight = ["Freight Type", "..."];
//   let incoterms = ["Incoterms", "..."];
//   let shipments = ["Shipment Type", "Road", "Train", "Air", "Sea"];

//   return (
//     <div
//       className="section-full p-t120 p-b90 site-bg-gray tw-booking-area tw-booking-area-bg-wrap"
//       style={{ backgroundImage: `url(${_data.background})` }}
//     >
//       <div
//         className="tw-booking-area-bg"
//         style={{ backgroundImage: `url(${_data.imageBack})` }}
//       ></div>

//       <div className="container">
//         {/* <!-- TITLE START--> */}
//         <div className="section-head center wt-small-separator-outer">
//           <div className="wt-small-separator site-text-primary">
//             <div>{_data.title}</div>
//           </div>
//           <h2 className="wt-title">{_data.subtitle}</h2>
//           <p className="section-head-text">{_data.description}</p>
//         </div>
//         {/* <!-- TITLE END--> */}

//         {/* Content and Image Side by Side */}
//         <div className="tw-booking-content-image" style={{ display: "flex", alignItems: "center" }}>
//           <div className="tw-booking-content" style={{ flex: 1 }}>
//             {/* Place any additional content here */}
//           </div>
//           <div className="tw-booking-image" style={{ flex: 1 }}>
//             <img
//               src="../assets/images/newsletter.png"
//               alt="Booking"
//               style={{ maxWidth: "100%", height: "auto", objectFit: "cover" }}
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
